import { graphql, PageProps } from 'gatsby';
import React from 'react';
import BuildsPage from '../../components/pages/builds/BuildsPage';
import Layout from '../../components/Layout';
import SeoHeader from '../../components/SeoHeader';

const dayjs = require('dayjs');
const relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(relativeTime);

const layout = {
  SeoProps: {
    title: 'Builds Overview',
    description:
      'Discover all the fractal builds we provide on Discretize to help you play the desired profession to perfection.',
  },
  ContainerProps: {
    paper: false,
  },
};

export default function BuildsPageWrapper({
  data,
  location,
}: PageProps<Queries.BuildsPageQuery>) {
  return (
    <Layout ContainerProps={layout.ContainerProps} location={location}>
      <SeoHeader path={location.pathname} {...layout.SeoProps} />

      <BuildsPage data={data} />
    </Layout>
  );
}

export const query = graphql`
  query BuildsPage {
    images: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativePath: { glob: "professions/*" }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(
              width: 591
              height: 337
              transformOptions: { cropFocus: ENTROPY }
            )
          }
        }
      }
    }

    builds: allMdx(
      filter: {
        fields: { section: { eq: "builds" } }
        frontmatter: { hidden: { eq: false }, archive: { eq: false } }
      }
    ) {
      group(field: frontmatter___role) {
        fieldValue
        edges {
          node {
            frontmatter {
              title
              date
              profession
              specialization
              role
              classification
            }
            fields {
              slug
            }
          }
        }
      }
    }
  }
`;
